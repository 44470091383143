import styled from 'styled-components'

export const Template1Container = styled.div`
  width: 100%;
  .rsvp-bg {
    display: inline-block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    & img {
      height: auto;
      width: 100%;
    }
  }
`

export const Template2Container = styled.div`
  width: 100%;
  .rsvp-bg {
    display: inline-block;
    height: auto;
    margin-bottom: 150px;
    position: relative;
    top: -1.5rem;
    width: 100%;
    img {
      height: auto;
      width: 100%;
    }
    .font-lf {
      left: 0;
      position: absolute;
      top: 1.5rem;
      width: 100%;
    }
    .template-extra-item {
      bottom: -150px;
      position: absolute;
      right: 50px;
      .default {
        align-items: center;
        background-color: #edbda7;
        display: flex;
        height: 476px;
        justify-content: center;
        margin: 0 auto;
        width: 300px;
        .frame-white {
          align-items: center;
          background-color: white;
          display: flex;
          height: 446px;
          justify-content: center;
          width: 269px;
        }
        .frame {
          background-color: white;
          border: 2px solid #a06239;
          color: #a06239;
          display: flex;
          flex-direction: column;
          height: 426px;
          justify-content: space-between;
          padding: 10px 5px;
          text-align: center;
          width: 249px;
          .extra {
            font-size: 13px;
          }
          .couple {
            font-family: 'Cormorant Garamond', serif;
            font-size: 36px;
            font-weight: 500;
            margin: 0 auto;
            width: fit-content;
            > span {
              display: block;
              .and {
                background-color: white;
                display: inline-block;
                padding: 0 5px;
                position: relative;
                width: fit-content;
                z-index: 1;
              }
              .line {
                background-color: #a06239;
                display: block;
                height: 1px;
                position: relative;
                top: -27px;
                width: 100%;
              }
            }
            .bride_fn,
            .bride_ln,
            .groom_fn,
            .groom_ln {
              line-height: 1.1;
            }
            .bride_ln,
            .groom_ln {
              font-size: 24px;
            }
          }
        }
      }
    }
    .mw-landing-img {
      bottom: -100px;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      height: 300px;
      left: 10px;
      object-fit: cover;
      pointer-events: none;
      position: absolute;
      transition: width 0.3s ease, height 0.3s ease;
      width: 300px;
      z-index: 2;
      &.near-top {
        bottom: unset;
        height: 100px;
        position: fixed;
        transition: width 0.3s ease, height 0.3s ease;
        top: 60px;
        width: 100px;
      }
    }
  }
  @media (max-width: 1100px) {
    .rsvp-bg {
      margin-bottom: 240px;
      .template-extra-item {
        bottom: -240px;
        right: 10px;
      }
    }
  }
  @media (max-width: 900px) {
    .rsvp-bg {
      margin-bottom: 540px;
      .template-extra-item {
        bottom: -540px;
        right: 0;
        width: 100%;
        .default {
          position: relative;
          top: -30px;
        }
      }
      .mw-landing-img {
        height: 150px;
        width: 150px;
      }
    }
  }
  @media (max-width: 700px) {
    .rsvp-bg {
      .mw-landing-img {
        height: 100px;
        width: 100px;
      }
    }
  }
  @media (max-width: 600px) {
    .rsvp-bg {
      h1 {
        font-size: 60px;
      }
    }
  }
  @media (max-width: 450px) {
    .rsvp-bg {
      h1 {
        font-size: 50px;
      }
      .mw-landing-img {
        height: 80px;
        width: 80px;
      }
    }
  }
`
