import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { MdAdd } from 'react-icons/md'

import Arrow from '../../../assets/images/icons/arrow.svg'
import CreateEvent from '../../../pages/SocialPanel/CreateEvent'
import { HowItWorks } from '../../../components/Navbar'

import { CreateEventCardContainer } from './style'

export const CreateEventCard: React.FC<{ complete?: boolean }> = ({
  complete = false,
}) => {
  const [createEvent, setCreateEvent] = useState(false)
  const [isHowItWorksModalOpen, toggleHowItWorksModal] = useState(false)
  return (
    <>
      <CreateEvent show={createEvent} onClose={() => setCreateEvent(false)} />
      <CreateEventCardContainer
        className={complete ? 'create-bg' : 'create-sm'}
      >
        <div className="text-right">
          <img src={Arrow} alt="->" className="mb-3 mt-1 arrow-create" />
          <h2 className="text-primary fz-16 fwb">
            Your next gathering
            <br />
            begins here!
          </h2>
        </div>
        {complete && (
          <div className="text-center">
            <Button
              onClick={() => setCreateEvent(true)}
              className="fwsb d-block btn-outline-salmon-simple"
            >
              <MdAdd className="svg-top1" size={20} />
              Create Event
            </Button>
            <span
              className="cursor d-inline-block fz-13 mt-2"
              onClick={() => toggleHowItWorksModal(true)}
            >
              <u data-cy="how-it-works-gz">How We Work</u>
            </span>
            <HowItWorks
              open={isHowItWorksModalOpen}
              handle={toggleHowItWorksModal}
            />
          </div>
        )}
      </CreateEventCardContainer>
    </>
  )
}
