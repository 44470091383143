import React, { useEffect, useState } from 'react'
import getOr from 'lodash/fp/getOr'
import Row from 'react-bootstrap/Row'
import get from 'lodash/fp/get'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useQuery } from '@apollo/react-hooks'
import { Helmet } from 'react-helmet'

import { useMoveToTop, getMoreData } from '../../common'
import { GET_BLOG_POSTS } from '../../graphql'
import { SimpleLoader } from '../../components'
import { MakeoutSessions } from '../MakeoutSessions'
import { IyoutubeItem, Article } from '../../types'

import { makeArticleChunks, BlogHomeRow } from '.'

export const HomeBlog: React.FC = () => {
  useMoveToTop()
  const [hasMore, setHasMore] = useState(true)
  const [youtubeList, setYoutubeList] = useState<IyoutubeItem[]>([])
  const [articlesChunks, setArticlesChunks] = useState<Article[][]>([])

  const { data, loading, fetchMore } = useQuery(GET_BLOG_POSTS)
  const entries: Article[] = getOr([], 'blog.entries', data)
  const cursorToken = getOr(null, 'blog.cursor', data)

  useEffect(() => {
    setHasMore(cursorToken !== null)
    setArticlesChunks(makeArticleChunks(entries, youtubeList))
  }, [cursorToken, entries, youtubeList])

  const fetchMoreData = () => {
    if (cursorToken) {
      getMoreData({
        fetchMore,
        query: GET_BLOG_POSTS,
        cursorToken,
        path: 'blog',
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Kiss & Tell | Destination Wedding Inspiration</title>
        <meta
          name="description"
          content="Get inspired by real weddings and articles on destination wedding planning."
        />
      </Helmet>
      <h1 className="my-4 text-center fz-35 fwl">Real Weddings & Articles</h1>
      <section className="posts-list mx-auto mw-1200">
        <MakeoutSessions
          className="bg-white shadow-sm mb-4"
          getList={setYoutubeList}
        />

        <InfiniteScroll
          className="w-100"
          style={{ overflowX: 'hidden' }}
          dataLength={entries.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<SimpleLoader />}
        >
          <Row noGutters>
            {articlesChunks.length === 0 && !loading && (
              <p className="w-100 text-center tc-lgray pt-5 fz-18 fwsb">
                There are no results to show
              </p>
            )}
            {articlesChunks.map((el, idx) => (
              <BlogHomeRow
                videoList={youtubeList}
                articles={el}
                index={idx}
                key={get('[0].id', el)}
              />
            ))}
          </Row>
        </InfiniteScroll>
      </section>
    </>
  )
}
