import forEach from 'lodash/fp/forEach'

import { Image, IyoutubeItem, Article, IvideoCardPosition } from '../../types'

export const makeGroups = (list: Image[]): Image[][] => {
  const group1: Image[] = []
  const group2: Image[] = []
  const group3: Image[] = []
  let group = 0

  forEach(el => {
    group += 1
    if (group === 1) {
      group1.push(el)
    } else if (group === 2) {
      group2.push(el)
    } else if (group === 3) {
      group3.push(el)
      group = 0
    }
  }, list)

  return [group1, group2, group3]
}

/**
 * Return array of array
 * 1 array per Row
 * 3 elements, 2 elements, 3 elements, 2 elements...
 */
export const makeArticleChunks = (
  array: Article[],
  videos: IyoutubeItem[]
): Article[][] => {
  const chunkedArr: Article[][] = []

  if (!array && !videos) {
    return chunkedArr
  }

  let size = 3
  let index = 0

  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size

    if (chunkedArr.length < videos.length * 2) {
      size = size === 3 ? 2 : 3
    } else {
      size = 3
    }
  }

  return chunkedArr
}

export const makeVideoCardPositions = (
  videoListLength: number
): IvideoCardPosition[] => {
  const accum: IvideoCardPosition[] = []
  let align = 'right'
  let idxSum = 1
  for (let i = 0; i < videoListLength; i += 1) {
    accum.push({
      id: `video${i + idxSum}`, // video1, video3, video5, video7......
      align,
      idx: i,
    })

    align = align === 'right' ? 'left' : 'right' // to toggle between right and left
    idxSum += 1 // to get the correct position row 1, 3, 5, 7......
  }

  return accum
}
