/* eslint-disable @typescript-eslint/ban-types */
// in this case Function and object are necessary because it's an apollo function
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import camelCase from 'lodash/fp/camelCase'
import getOr from 'lodash/fp/getOr'
import set from 'lodash/fp/set'
import flow from 'lodash/fp/flow'
import toNumber from 'lodash/fp/toNumber'
import split from 'lodash/fp/split'
import last from 'lodash/fp/last'
import first from 'lodash/fp/first'
import orderBy from 'lodash/fp/orderBy'
import { DocumentNode } from 'graphql'

import {
  Error,
  IuseQueryParams,
  Maybe,
  PackageAddons,
  EventComponent,
  EmbedVideoType,
  IgenericObject,
} from '../types'

import { simpleAlert } from './alerts'

export const checkEnv = (): {
  prod: boolean
  develop: boolean
} => {
  const NODE_ENV = process?.env?.NODE_ENV
  // there is a 3rd env(test) but it's not necessary right now
  const develop = NODE_ENV === 'development'
  const prod = !develop

  return { prod, develop }
}

export const useMoveToTop = (top = 0): void => {
  useEffect(() => {
    const { develop } = checkEnv()
    if (develop) return

    window.scrollTo({
      top,
    }) // Scroll to top
  }, [top])
}

// Custom hook to get query params without third party module
export const useQueryParams = (): IuseQueryParams => {
  const query = new URLSearchParams(useLocation().search)
  const params: IgenericObject = {}

  query.forEach((value, key) => {
    const keyCamelCase = camelCase(key)
    params[keyCamelCase] = value
  })

  return params
}

interface IgetMoreData {
  fetchMore: Function
  query: DocumentNode
  variables?: { [s: string]: string | number }
  cursorToken: string
  path: string
}
export const getMoreData = ({
  fetchMore,
  query,
  variables = {},
  cursorToken,
  path,
}: IgetMoreData): void => {
  fetchMore({
    query,
    variables: {
      ...variables,
      cursor: cursorToken,
    },
    updateQuery: (
      previousResult: object,
      { fetchMoreResult }: { fetchMoreResult: object }
    ) => {
      if (!fetchMoreResult) {
        return previousResult
      }

      const previousEntries = getOr([], `${path}.entries`, previousResult)
      const newEntries = getOr([], `${path}.entries`, fetchMoreResult)
      const newCursor: string = getOr(null, `${path}.cursor`, fetchMoreResult)
      const typename: string = getOr('', `${path}.__typename`, previousResult)

      return set(
        path,
        {
          entries: [...previousEntries, ...newEntries],
          cursor: newCursor,
          __typename: typename,
        },
        previousResult
      )
    },
  })
}

export const errorAlert = (errors: Error[], msg: string): void => {
  let errorMsg = `<p>${msg}</p>`
  errors?.forEach(({ key, message }: Error) => {
    errorMsg = `${errorMsg}<p class="text-left w-75 mx-auto">${key}: ${message}</p>`
  })
  simpleAlert({ html: errorMsg, icon: 'warning' })
}

export const getCoords = (geoPoint: string): [lat: number, lng: number] => {
  /**
   * example coord:
   * SRID=4326;POINT(-89.653218 20.68508)
   * -89.653218 20.68508)
   * -89.653218 20.68508
   * [-89.653218, 20.68508]
   */
  const LatLang = flow(
    split('('),
    last,
    split(')'),
    first,
    split(' ')
  )(geoPoint)

  const lat = toNumber(LatLang[1]) || 0
  const lng = toNumber(LatLang[0]) || 0

  return [lat, lng]
}

export const orderPackageAddons = (
  addons: Maybe<PackageAddons[]>,
  order: boolean | 'asc' | 'desc' = 'asc'
): PackageAddons[] => {
  if (!addons) return []

  return orderBy(
    [(el: PackageAddons) => !el.time, 'time', 'name'],
    [order, order, order],
    addons
  )
}

export const sortPackagesBySubEvent = (
  ecs: EventComponent[] = []
): EventComponent[] => {
  if (ecs.length <= 1) return ecs
  const packages = ecs.map(el => ({
    ...el,
    packageCustomization: orderPackageAddons(el.packageCustomization),
  }))

  return orderBy(['packageCustomization[0].time'], ['asc'], packages)
}

/**
 * Check if a string is a valid youtube url
 * @param url string
 * @returns boolean
 */
export const isYouTubeUrl = (url?: Maybe<string>): boolean => {
  if (!url) return false
  const youtubeRegex =
    /^(https?:\/\/)?((www\.)?youtube\.com\/((watch\?v=|embed\/)|v\/|shorts\/)?([a-zA-Z0-9_-]{11}).*|youtu\.be\/([a-zA-Z0-9_-]{11}))\??.*$/
  return youtubeRegex.test(url)
}
/**
 * Get the youtube video id from a youtube url
 * @param url string
 * @returns string | null
 */
export const getYouTubeVideoId = (url: Maybe<string>): string | null => {
  if (!url) return null
  const regExp =
    /^.*?(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([a-zA-Z0-9_-]{11}).*$/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

/**
 * Check if a string is a valid instagram reel url
 * @param url string
 * @returns boolean
 */
export const isInstagramReelUrl = (url?: Maybe<string>): boolean => {
  if (!url) return false
  const instagramReelRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/reel\/[a-zA-Z0-9_-]+\/?.*$/
  return instagramReelRegex.test(url)
}
/**
 * Get the instagram reel id from an instagram reel url
 * @param url string
 * @returns string | null
 */
export const getInstagramReelId = (url: string): string | null => {
  const regExp =
    /^(https?:\/\/)?(www\.)?instagram\.com\/reel\/([a-zA-Z0-9_-]+)\/?.*$/
  const match = url.match(regExp)
  return match && match[3] ? match[3] : null
}

/**
 * Get the type of video from a url
 * @param url string
 * @returns EmbedVideoType | void
 * for now we only support youtube and instagram
 */
export const getVideoType = (url?: Maybe<string>): EmbedVideoType => {
  if (isYouTubeUrl(url)) return 'youtube'
  if (isInstagramReelUrl(url)) return 'instagram'
  return 'unknown'
}
