import React from 'react'
import LazyLoad from 'react-lazyload'

import List from '../../../assets/images/icons/list.svg'
import Group from '../../../assets/images/icons/group.svg'
import Celebrate from '../../../assets/images/icons/celebrate.svg'

import { ImgCont } from './style'

export const Benefit: React.FC<{ data: number }> = ({ data }) => {
  const benefits = [
    {
      icon: List,
      title: 'Host your Big Event',
      text: `We make research and budgeting a breeze. Create as many events that you can dream up, and discuss the finer details with our curated and trustworthy vendors.`,
    },
    {
      icon: Group,
      title: 'Grateful Guests',
      text: `Guests manage the entire lifecycle of their attendance to one or many events and feel part of the group conversation.`,
    },
    {
      icon: Celebrate,
      title: 'Value for Vendors',
      text: `Showcase your event packages, and accommodations in a customizable, turnkey booking solution without sacrificing the personal touch.`,
    },
  ]
  return (
    <article className="m-auto text-center">
      <ImgCont>
        <LazyLoad height={80} once>
          <img
            src={benefits[data].icon}
            alt={benefits[data].title}
            className="benefit-img"
          />
        </LazyLoad>
      </ImgCont>
      <h3 className="text-primary | fz-18 fwb | mt-3 mb-2 benefit-title">
        {benefits[data].title}
      </h3>
      <p
        className="d-inline-block | tc-lgray | benefit-text"
        style={{ maxWidth: '300px' }}
      >
        {benefits[data].text}
      </p>
    </article>
  )
}
