import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'

import CreateEvent from '../../pages/SocialPanel/CreateEvent'
import { useCurrentUser } from '../../common'
import { Caret } from '../../components/Navbar/style'
import { HowWeWorkBody } from '../../components/Navbar/components'

export const HowWeWork: React.FC = () => {
  const { isAuthenticated } = useCurrentUser()
  const [createEvent, setCreateEvent] = useState(false)
  const [registerModal, openRegisterModal] = useState(false)

  const handleCTA = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (isAuthenticated) {
      setCreateEvent(true)
    } else {
      openRegisterModal(true)
    }
  }

  return (
    <section className="mx-auto py-5 px-2" style={{ maxWidth: '900px' }}>
      <Helmet>
        <title>
          Kiss & Tell | Destination Weddings Made Simple | How We Work
        </title>
      </Helmet>
      <h1 className="text-center text-primary fwb">
        Let Us Make{' '}
        <span className="d-block d-md-inline-block pt-2 pt-md-0">
          Your <Caret className="font-lf">Big</Caret> Day
        </span>
      </h1>
      <HowWeWorkBody
        openRegisterModal={openRegisterModal}
        registerModal={registerModal}
      >
        <Button
          onClick={handleCTA}
          className="px-5 fz-16 mx-auto d-block cta-how-we-work"
        >
          Get Started
        </Button>
      </HowWeWorkBody>

      <CreateEvent
        redirectToHome
        show={createEvent}
        onClose={() => {
          setCreateEvent(false)
        }}
      />
    </section>
  )
}
