import React, { useEffect, useState } from 'react'
import axios from 'axios'
import get from 'lodash/fp/get'
import last from 'lodash/fp/last'
import slice from 'lodash/fp/slice'
import split from 'lodash/fp/split'
import reverse from 'lodash/fp/reverse'
import isEmpty from 'lodash/fp/isEmpty'
import unescape from 'lodash/fp/unescape'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { IframeYoutube } from '../common'
import { YoutubeKey, makeoutSessionsPlaylist } from '../common/constants'
import { IyoutubeList, IyoutubeItem } from '../types'
import MakeoutSessionsTitle from '../assets/images/MakeoutSessions.jpg'

interface IMakeoutSessions {
  fluid?: boolean
  className?: string
  videoHeight?: string
  getList?: React.Dispatch<React.SetStateAction<IyoutubeItem[]>>
}

export const MakeoutSessions: React.FC<IMakeoutSessions> = ({
  fluid = false,
  className = '',
  getList,
  videoHeight,
}) => {
  const [loading, setLoading] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [videoDescription, setVideoDescription] = useState('')

  useEffect(() => {
    if (!loading && isEmpty(videoUrl)) {
      setLoading(true)
      const playlistId = makeoutSessionsPlaylist
      const key = YoutubeKey
      const params = {
        part: 'snippet',
        maxResults: 50,
        playlistId,
        key,
      }
      axios
        .get('https://www.googleapis.com/youtube/v3/playlistItems', { params })
        .then(({ data }: { data: IyoutubeList }) => {
          const list = get('items', data) || []
          const lastVideo = last(list) || []

          if (getList) {
            const newList = slice(0, -1, list) // without most recent video
            getList(reverse(newList))
          }

          const title = get('snippet.title', lastVideo)
          const description =
            split('\n\n', get('snippet.description', lastVideo)) || []
          const id = get('snippet.resourceId.videoId', lastVideo)
          setVideoUrl(id)
          setVideoTitle(title)
          setVideoDescription(
            `${get('[0]', description)}\n\n${get('[1]', description)}`
          )
        })
        .catch(() => {
          setVideoUrl('qFrREUQufQA') // default Intro video
          setVideoTitle('Makeout Sessions Series Intro')
          setVideoDescription(
            `When life gives us lemons, we make lemonade, or in our case, #MakeoutSessions! Tune in every Wednesday and Sunday at 2:00 pm EST to chat all things #relationships, #dating, #weddings and #travel in the time of #coronavirus #covid19 and join us on our Instagram Live After Party at @kissandtellofficial.`
          )
        })
        .then(() => {
          setLoading(false)
        })
    }
  }, [getList, loading, videoUrl])

  return (
    <Container
      fluid={fluid}
      className={`makeout-sessions-card p-3 p-md-5 ${className}`}
    >
      <Row>
        <Col xs={12} lg={5}>
          <img
            src={MakeoutSessionsTitle}
            alt="Makeout Sessions"
            className="d-block mx-auto mt-2 mb-3 w-100"
            style={{ maxWidth: '320px' }}
          />
          <p className="fwsb">{videoTitle}</p>
          <div style={{ whiteSpace: 'pre-wrap', marginBottom: '10px' }}>
            {unescape(videoDescription)}
          </div>
        </Col>
        <Col xs={12} lg={7} className="d-flex align-items-center">
          <IframeYoutube videoId={videoUrl} height={videoHeight} />
        </Col>
      </Row>
    </Container>
  )
}
