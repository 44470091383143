import React, { useState, useEffect } from 'react'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import findIndex from 'lodash/fp/findIndex'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useMutation } from '@apollo/react-hooks'

import { useAppSelector } from '../../../hooks'
import { KnTField, Textarea } from '../../../components'
import { useForm } from '../../../common/alterForms'
import { GET_EVENT_FEEDS, UPDATE_POST } from '../../../graphql'
import {
  Post,
  Error,
  Maybe,
  FeedQuery,
  PollOptionInput,
  IupdatePostVariables,
} from '../../../types'
import {
  getVideoType,
  isInstagramReelUrl,
  isYouTubeUrl,
  simpleAlert,
} from '../../../common'

import { PollUpdate } from '.'

export const EditPost: React.FC<{
  show: boolean
  setShow: (show: boolean) => void
  post: Post
}> = ({ show, setShow, post }) => {
  const init = { body: '', video: '' }
  const { model, errors, handleFieldChange, pushError } = useForm(init)
  const { currentEventId } = useAppSelector(state => state.site)

  // Poll config
  const [incompletePoll, setIncompletePoll] = useState(false)
  const [deletePoll, setDeletePoll] = useState(false)
  const [options, setOptions] = useState<PollOptionInput[]>([])
  const [deadline, setDeadline] = useState<string>()

  useEffect(() => {
    if (!model.body) {
      model.body = post.body // force refresh of body property
      model.video = post.video ?? ''
    }
    if (post?.poll) {
      setOptions(post.poll.options)
    }
    setDeadline(post.deadline)
  }, [model, post.body, post.deadline, post.poll, post.video])

  const [editPostMutation, { loading: loadingEdit }] = useMutation(
    UPDATE_POST,
    {
      update(cache, { data: { updatePost } }) {
        if (updatePost.errors.length === 0) {
          const cached: Maybe<FeedQuery> = cache.readQuery({
            query: GET_EVENT_FEEDS,
            variables: { eventIds: [currentEventId] },
          })
          if (cached) {
            const posts = get('feed.entries', cached) || []
            const postIndex = findIndex({ id: post.id }, posts)
            cache.writeQuery({
              query: GET_EVENT_FEEDS,
              variables: { eventIds: [currentEventId] },
              data: set(
                `feed.entries[${postIndex}]`,
                updatePost.result,
                cached
              ),
            })
          }
        }
      },
    }
  )

  // for the moment we only support youtube and instagram videos
  const videoType = getVideoType(post.video)

  const handleEditPost = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    ev.preventDefault()
    if (loadingEdit) return

    if (videoType === 'instagram' && !isInstagramReelUrl(model.video)) {
      pushError('Invalid Instagram Reel URL', 'video')
      return
    }
    if (videoType === 'youtube' && !isYouTubeUrl(model.video)) {
      pushError('Invalid YouTube URL', 'video')
      return
    }

    const tierIds = post.tiers?.map(el => el.id)
    const variables: IupdatePostVariables = {
      id: post.id,
      input: {
        body: model.body,
        video: model.video,
        poll: null,
      },
      tierIds,
    }

    if (post?.poll && !incompletePoll && !deletePoll) {
      variables.input.poll = {
        multi: post.poll.multi,
        options: options.map(el => ({ id: el.id, name: el.name })),
      }
      variables.input.deadline = deadline || null
    }

    editPostMutation({ variables })
      .then(({ data }) => {
        if (data.updatePost.errors.length > 0) {
          data.updatePost.errors.forEach(({ key, message }: Error) => {
            pushError(message, key)
          })
        } else {
          setShow(false)
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'An error occurred while updating the post',
          icon: 'error',
        })
      })
  }

  const closeEditPostModal = () => {
    setShow(false)
    setIncompletePoll(false)
    setDeletePoll(false)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header className="py-3">
        <h5 className="font-weight-bold m-0">Edit Post</h5>
      </Modal.Header>
      <Modal.Body>
        <Textarea
          placeholder="Editing post..."
          error={errors}
          value={model.body}
          change={handleFieldChange}
          name="body"
          inputClassName="input-outline-none rounded-0"
        />

        {post?.poll && !deletePoll && (
          <PollUpdate
            error={false}
            oldOptions={options}
            setOptions={setOptions}
            deletePoll={setDeletePoll}
            incompletePoll={setIncompletePoll}
            deadline={deadline}
            setDeadline={setDeadline}
          />
        )}

        {post.video && (
          <div className="">
            <KnTField
              className="rounded-0"
              label={`${videoType === 'youtube' ? 'YouTube' : 'Instagram'} URL`}
              name="video"
              value={model}
              setValue={handleFieldChange}
              error={errors}
              placeholder={
                videoType === 'youtube'
                  ? 'e.g. https://www.youtube.com/watch?v=videoId'
                  : 'e.g. https://www.instagram.com/reel/CrjWuiWAIL9'
              }
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loadingEdit || incompletePoll}
          onClick={handleEditPost}
          variant="primary"
        >
          Update
        </Button>
        <Button onClick={() => closeEditPostModal()} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
