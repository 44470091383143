import React from 'react'

import {
  getInstagramReelId,
  getYouTubeVideoId,
  isInstagramReelUrl,
  isYouTubeUrl,
} from '../../../common'

import { EmbedVideoContainer } from './style'

export const VideoPost: React.FC<{ video: string }> = ({ video }) => {
  if (isYouTubeUrl(video)) {
    const videoId = getYouTubeVideoId(video)
    return (
      <EmbedVideoContainer className="embed-youtube-video">
        <iframe
          title="Youtube Video"
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </EmbedVideoContainer>
    )
  }

  if (isInstagramReelUrl(video)) {
    const reelId = getInstagramReelId(video)
    return (
      <EmbedVideoContainer className="embed-instagram-reel">
        <iframe
          src={`https://www.instagram.com/reel/${reelId}/embed/?utm_source=ig_embed`}
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          title="Instagram Reel"
        />
      </EmbedVideoContainer>
    )
  }

  return null
}
